// custom styles for the site


// homepage styles
.home {
    .page-heading {
        .container {
            max-width: 1600px;
        }
    }

    .logos {
        padding: 25px 0px 40px;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $medium-screen) {
            padding: 15px 0px;
        }
        .col {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 0 auto;
        }
        img {
            @media (max-width: #{ $medium-screen - 1}) {
                align-self: center !important;
            }
        }
    }
}

.page-heading {
    .container {
        max-width: 1400px!important;
        padding: 0 16px;
    }
}
@media (max-width: 800px) {
    .page-heading, .shortcode-featured-post {
        margin-bottom: 1em;
        .angle-post {
            display: none;
        }
    }
}

.page-heading {
    padding: 7% 0 calc(10% - 2vw);
    position: relative;
    background: rgba(0, 92, 181, 0.62);
    background-size: cover;
    background-position: top left;
    background-repeat: no-repeat;
    &.slip-rib-hero {
        min-height: calc(40vw - 10vh);
        // min-height: clamp(40vw, 40vw, 20vh);
        @media (max-width: 950px) {
            min-height: 50vw;
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
    & + .container, & ~ .results-container {
        padding-top: calc(1.25em - 1vw);
    }
    h1, h2, p {
        color: white;
        max-width: 767px;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, .6)!important;
        strong {
            font-size: 1.5em;
            font-weight: normal;
            line-height: 1;
        }
        @media (max-width: 992px) {
            // margin-left: auto;
            // margin-right: auto;
        }
    }
    h1 {
        margin-bottom: 15px;
        &.bold {
            font-weight: bold!important;
        }
    }
    p {
        color: white;
        margin: 0 0 8px 0 !important;
        text-shadow: 1px 1px 8px rgba(0, 0, 0, .6);
        font-size: em(22);
        strong {
            font-size: 1.3em;
        }
    }
    a {
        text-shadow: none;
    }
    .usa-button {
        font-size: em(24);
    }
    @media (max-width: $breakpoint-phone) {
        padding: 10% 0 7.25%;
        h1 {
            margin-bottom: 8px;
        }
    }
    .svg-grid {
        position: absolute;
        background-image: url('/wp-content/themes/uhc-uswds/img/svg-grid.svg');
        top: -5%;
        bottom: 0;
        left: -24%;
        right: 18%;
        opacity: .25;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        z-index: 1;
    }
    .gradient-circle-large {
        position: absolute;
        top: 20%;
        height: 20%;
        width: auto;
        bottom: 50%;
        right: 10%;
        left: 70%;
        opacity: 0.85;
        transform: rotate(125deg);
    }
    .gradient-circle-small {
        position: absolute;
        top: 10%;
        height: 8%;
        width: auto;
        bottom: 70%;
        right: 5%;
        left: 85%;
        opacity: 0.85;
        transform: rotate(75deg);
    }
}
.blog-heading {
    background-position: center;
    position: relative;
    overflow: hidden;
    &.page-heading {
        padding: 14vw 0 13vw!important;
    }
    & > .container {
        display: flex;
        flex-direction: column;
        justify-items: center;
    }
    .blog-titlebg {
        position: absolute;
        background-color: rgba(104, 117, 99, 0.62);
        height: 1500px;
        width: 1250px;
        top: -106%;
        transform: rotate(33.75deg);
        left: -7%;
        @media (max-width: $large-screen) {
            left: -24%;
        }
    }
    @media (min-width: 650px) {
        &.page-heading {
            padding: 10vw 0 13vw!important;
        }
        & > .container {
            min-height: 160px!important;
        }
    }
}

body {
    // sidebar
    aside {
        position: relative;
        padding-bottom: 1%;
        margin-top: 1em;
        @media (min-width: 1200px) {
            margin-top: -230px;
        }
        @media (max-width: $medium-screen) {
            top: 0;
            border: none;
            margin-bottom: 4em;
        }
        .sidebar-widget {
            ul {
                list-style-type: none;
            }
        }
        .sidebar-card {
            @extend .card;
            // max-width: 433px !important;
            position: relative;
            margin-top: 0 !important;
            border-radius: 6px !important;
            background-position: top;
            background-size: contain;
            background-repeat: no-repeat;
            border: none !important;
            transform: none !important;
            //overflow: hidden;
            .sidebar-screening a {
                margin-bottom: 0;
            }
            @media (max-width: #{$large-screen - 1}) {
                //margin-top: 100px !important;
                margin-right: auto !important;
                margin-left: auto !important;
            }
            img.card-image-full {
                position: absolute;
                top: -105px;
                width: 100%;
                max-width: calc(100% + 4px) !important;
            }
            .textwidget {
                & > div {
                    padding: 6px em(16);
                }
                .lead {
                    font-family: $helvetica-condensed;
                    font-size: em(46);
                    line-height: 1;
                    color: white;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin: 0!important;
                    z-index: 1;
                    position: relative;
                    span:not(.subline) {
                        padding-right: calc(1.75em + 1vw);
                        display: block;
                    }
                    span.subline {
                        color: $uhc-gold;
                        font-weight: normal;
                        text-transform: initial;
                        display: block;
                        line-height: 1.1;
                        margin-top: 0.15em;
                        font-size: 80%;
                    }
                }
                ul {
                    @include StyledList($uhc-gold);
                    font-family: $helvetica;
                    font-size: em(20);
                    color: #000;
                }
            }
            .healthgrade-logo {
                width: 298px;
                display: block;
                margin: 0 auto 18px auto;
            }
            .healthgrade-stars {
                background-color: #687385;
                border-radius: 3px;
                padding: 10px 20px;
                text-align: center;
                color: white;
                font-size: em(32);
                max-width: 298px;
                margin: 0 auto;
                box-shadow: $box-shadow;
                transition: $global-transition;
                &:hover {
                    box-shadow: 0 20px 30px rgba(0, 0, 0, .1);
                    transform: translate(-2px, -2px);
                }
            }
            a.hg-link {
                text-decoration: none;
            }
            .sidebar-blue-text {
                text-align: center;
                font-size: em(32);
                font-family: $helvetica-condensed;
                color: #1162a9;
                text-transform: uppercase;
                margin-bottom: 8px;
            }
            .sidebar-card-title,
            .sidebar-card-content {
                padding: 7.5%;
            }
            .sidebar-card-title {
                background: #f0b220;
                font-size: em(18);
            }
            .sidebar-card-content {
                background: white;
                ul {
                    @include StyledList;
                }
            }
        }
        iframe {
            max-width: 100%;
        }
        // widget title
        h5 {
            font-family: $helvetica;
            color: $uhc-blue;
            padding: 16px 1.5em 24px 1.5em;
            border-bottom: 1px solid #a8b4cc;
            margin-bottom: 30px;
            font-size: em(21);
            line-height: $global-line-height;
            span {
                font-weight: bold;
                font-family: $helvetica;
                display: block;
                text-transform: uppercase;
                font-size: 1.2em;
            }
        }

        .card {
            @media (max-width: #{ $nav-width - 1 }) {
                border: none;
                &:hover {
                    box-shadow: none;
                }
            }
        }
        .link-wrap {
            position: relative;
            display: block;
            width: 100%;
            iframe {
                z-index: 2;
            }
            img {
                display: block;
                margin: 0 auto;
                box-shadow: $box-shadow-large;
            }
        }
        .blocker {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
        }
        // end aside
    }
    aside.post-sidebar {
        box-shadow: $box-shadow-large;
        @media (max-width: #{ $nav-width - 1 }) {
            padding: 0;
            border: none;
        }
    }

    // House Call Sidebar Styles
    aside.post-sidebar .custom-html-widget>div.housecall, aside .sidebar-card-lp .custom-html-widget>div.housecall, .sidebar-card .custom-html-widget>div.housecall {
        background: $uhc-blue!important;
        position: relative;
        padding-top: 2.5em!important;
        padding-bottom: 2em!important;
        border-radius: 6px 6px 0 0;
        &:before {
            content: "";
            position: absolute;
            left: -4.2em;
            bottom: 0;
            width: 32.5em;
            background-image: url('/wp-content/themes/uhc-uswds/img/wave-blobs.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 115%;
            opacity: .45;
            z-index: 0;
        }
        &:after {
            content: "";
            position: absolute;
            top: 2.3em;
            right: 1.25em;
            width: 115px;
            height: 55px;
            background-image: url('/wp-content/themes/uhc-uswds/img/animated-email-icon022120_2.gif');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .lead {
            font-family: $helvetica-condensed;
            font-size: em(42);
            line-height: 1;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0!important;
            z-index: 1;
            position: relative;
            text-align: left!important;
            span:not(.subline) {
                padding-right: calc(1.75em + 1vw);
                display: block;
            }
            span.subline {
                color: #eea904;
                font-weight: 400;
                text-transform: initial;
                display: block;
                line-height: 1.1;
                margin-top: .15em;
                font-size: 80%;
            }
        }
        @media (max-width: 768px) {
            padding-top: 2.25em!important;
            &:before {
                width: 28em;
            }
            &:after {
                width: 100px;
                top: 1.8em;
            }
            .lead {
                font-size: calc(#{em(30)} + 1vw);
            }
        }
        @media (max-width: $breakpoint-phone) {
            padding: 2em 1em 1.5em 1em!important;
            &:after {
                width: 100px;
                top: 1.6em;
                right: 0.75em;
            }
        }
    }
    .sidebar-widget #gform_1, .sidebar-widget #gform_2 {
        padding: 1em 1.5em 1.5em!important;
        label.gfield_label {
            margin-top: 0.5em!important;
        }
        label.gfield_consent_label {
            font-size: 13.15px!important;
        }
        @media (min-width: 768px) {
            li#field_1_4, li#field_2_4 {
                width: 70%!important;
            }

            li#field_1_7, li#field_2_7 {
                width: 30%!important;
            }
        }
        @media (max-width: $breakpoint-phone) {
            padding: 0.5em 1em 1em!important;
        }
    }

    .gform_confirmation_wrapper {
        padding: 1.25em;
    }

    div#gform_wrapper_1, div#gform_wrapper_2 {
        margin: 0;
        margin-bottom: 1.5em;
        form#gform_1, form#gform_2 {
            padding: 2em;
            padding-top: 0;
            li.gfield {
                margin-top: 0;
                margin-bottom: 0;
                label.gfield_label, label.gfield_consent_label {
                    margin-top: 1em;
                }
            }
            input {
                margin-top: 0;
            }
            #input_1_3, #input_2_3 {
                margin-top: 0!important;
                margin-bottom: 3px;
            }
            input#gform_submit_button_1, input#gform_submit_button_2 {
                font-family: $helvetica-condensed!important;
                line-height: 1!important;
                font-size: 28px!important;
                height: initial!important;
                padding: 1.5rem 2rem!important;
                margin-bottom: 0;
                display: block;
                width: 100%!important;
                max-width: 100%!important;
                text-transform: uppercase;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2)!important;
                &:hover {
                    box-shadow: 0 3px 10px transparentize($uhc-gold, 0.5)!important;
                }
            }
            .ginput_container input {
                border-radius: 6px;
                border: 1px solid #b5b5b5;
                padding: 5px 12px;
                transition: $global-transition;
                &:focus, &:active {
                    border-color: #7f7f7f;
                }
            }
            span.gfield_required {
                color: $uhc-gold;
            }
            li#field_1_4, li#field_1_7,
            li#field_2_4, li#field_2_7 {
                @media (min-width: 768px) {
                    width: 50%;
                    display: inline-grid;
                    span.address_zip {
                        width: 100%;
                    }
                }
            }
            label.gfield_consent_label {
                font-size: em(14);
            }
            .gform_footer {
                padding-bottom: 0;
            }
            div.ginput_recaptcha {
                margin-top: 1.5em;
            }
            div#gform_confirmation_wrapper_1, div#gform_confirmation_wrapper_2 {
                margin: 2em;
                line-height: 1.5;
            }
        }
    }
}

.usa-header-basic {
    .usa-navbar {
        @media (max-width: #{$nav-width - 1}) {
            width: 100%;
        }
        .usa-logo {
            position: relative;
            display: flex;
            align-items: center;
            bottom: initial;
            & > a {
                flex: 1;
            }
        }
    }
    & > .usa-nav-container {
        display: flex;
        justify-content: space-between;
        background-color: white;
        // overflow: hidden;
        @media (min-width: $nav-width) {
            &:after {
                content: "";
                display: none;
            }
        }
    }
}

.usa-nav.is-visible {
    width: 30rem;
}

nav.usa-nav {
    @media (min-width: $nav-width) {
        display: inline-flex;
        align-items: center;
        .usa-nav-primary {
            margin-top: 0;
            display: flex;
            flex: 1;
            align-items: center;
            & > li {
                // 3 Styles below make it full navbar height
                height: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                min-width: 120px;
                & > a, button {
                    display: inline-flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    transform: skew(0deg, 0deg);
                    flex: 1;
                    padding: 16px 20px;
                    span {
                        transform: skew(33.75deg, 0deg);
                        padding-bottom: 0!important;
                    }
                }
                & > button {
                    background: none!important;
                    span {
                        position: relative;
                        &:after {
                            content: "";
                            background-image: url(./dist/img/angle-arrow-down.svg);
                            position: absolute;
                            right: 0em;
                            width: 9px;
                            height: 100%;
                            top: 0;
                            bottom: 0;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            transition: 0.15s ease-in-out;
                        }
                    }
                    &[aria-expanded=true] {
                        span:after {
                            opacity: 0;
                        }
                    }
                }
                ul.usa-nav-submenu {
                    display: inline-flex;
                    flex-wrap: wrap;
                    top: $navbar-height!important;
                    li {
                        flex: 1;
                        flex-basis: 100%;
                        display: inline-flex;
                        align-items: center;
                        padding: 0;
                        a {
                            transform: skew(33.75deg, 0deg);
                            flex: 1;
                            padding: 16px 24px;
                        }
                    }
                }
            }
        }
    }
}

aside.post-sidebar {
    margin-bottom: 2em;
    padding: 0;
    border: none;
    a.my-chart {
        display: block;
        color: #fff;
        text-decoration: none;
        font-weight: 700;
        text-align: center;
        font-size: 2.5em;
        padding: .5em 2em;
        transition: .2s all;
        border-radius: 6px;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        //top: -0.75em;
        position: relative;
    }
    @media (max-width: 1200px) {
        box-shadow: none!important;
    }
}

.featured-links {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 1.5em!important;
    h5 {
        padding-bottom: 18px;
        font-size: em(24);
    }
    a {
        display: inline-flex;
        width: 100%;
        height: 100px;
        padding: 1em 2em 1em 1em;
        background-image: linear-gradient(45deg, rgba(0, 40, 87, 1) 0%, rgba(0, 40, 87, 0.75) 40%, transparentize($uhc-gold, 0.75) 100%), var(--background-image);
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-blend-mode: multiply;
        filter: brightness(1.15) contrast(0.9);
        color: white;
        font-family: $iowan-old;
        font-size: 22px;
        text-decoration: none;
        align-items: center;
        opacity: 1;
        transition: $global-transition10;
        p {
            margin: 0;
            color: white!important;
            width: 100%;
            filter: brightness(0.85) contrast(1.1);
            text-shadow: 1px 1px 4px rgba(0,0,0,.4);
            &.gold-slash {
                padding-left: 1.2em;
                z-index: 1;
                &:before {
                    width: 2%;
                    z-index: -1;
                    height: 95%;
                    top: 42%;
                }
            }
        }
        &:hover {
            opacity: 0.93;
        }
        &:focus, &:active {
            opacity: 0.82;
        }
    }
}

.heading-container {
    h1 {
        color: white;
    }
}

.slip-rib-section {
    background-image: linear-gradient(rgba(0,92,181,.30),rgba(9, 34, 62, 0.30)), url('/wp-content/uploads/2020/10/slip-rib-hero-web.jpg');
    background-blend-mode: normal!important;
    background-position: center center!important;
    padding: 11% 0 12%!important;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0,92,181,.62);
}

// aside {
//     &.thoracic-page {
//         @media (max-width: 1200px) {
//             display: none;
//         }
//     }
//     &.mobile-thoracic-page {
//         @media (min-width: 1200px) {
//             display: none;
//         }
//     }
// }

// #P1.header-video {
//     position: absolute;
//     display: block!important;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     z-index: 1;
//     width: 100%;
//     height: 100%;
//     iframe {
//         width: 100%;
//         height: 100%;
//         max-width: 100%!important;
//     }
// }

.video-hero {
    video {
        width: 100%;
        height: auto;
        @media (max-width: 950px) {
            height: 100%;
            width: auto!important;
        }
    }
    @media (max-width: 768px) {
        display: none;
    }
}

.flex-container {
    display: flex;
    &.column {
        flex-direction: column;
    }
    &.slip-rip-card-container {
        justify-content: center;
        & > div {
            margin: 0.75em;
            border-radius: 6px;
            box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
            max-width: 550px;
        }
        img {
            display: block;
            border-radius: 6px 6px 0 0;
            width: 100%;
            height: auto;
        }
        p {
            margin: 0;
            color: $uhc-blue;
            padding: 0.5em 0.5em;
        }
        @media (max-width: 992px) {
            align-items: center;
        }
    }
    p:empty {
        display: none;
    }
    &.cl-992-max {
        @media (max-width: 992px) {
            flex-direction: column;
        }
    }
}

.iframe-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image-card {
    @extend .card;
    display: flex;
    flex-direction: column;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
    .image-card-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        min-height: 4em;
    }
    img {
        display: block;
        width: 100%;
        height: auto;
    }
    p {
        display: block;
        padding: 1.25em;
        margin: 0;
        &:empty {
            display: none;
        }
    }
}

.provider-row {
    .provider-container {
        margin-bottom: 0.5em;
        box-shadow: $box-shadow-large;
        border-radius: 6px;
        margin-right: 0.75em;
        .provider-info {
            text-align: center;
            & > div {
                border-radius: 6px;
                background-color: white;
                max-width: 220px;
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                height: 100%;
                & > div:first-of-type {
                    max-height: 320px;
                    overflow: hidden;
                    padding: 0;
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 6px 6px 0 0;
                        display: block;
                    }
                }
                & > div:last-of-type {
                    padding: 1em;
                    padding-bottom: 0.25em;
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                    display: flex;
                    flex-direction: column;
                    flex: auto;
                    h5 {
                        font-weight: 700;
                        font-size: 1.25em;
                        line-height: 1.2;
                        margin-bottom: auto;
                    }
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
        @media (min-width: 500px) {
            display: inline-grid;
        }
        //@media (min-width: 992px) {
        //    padding-right: 0.5em;
        //}
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: inline-block!important;
        }
    }
    @media (max-width: 750px) {
        justify-content: center;
    }
}