/*!
Theme Name: UHC USWDS
Theme URI: https://blaineturner.com
Author: BlaineTurner Advertising
Author URI:  http://blaineturner.com/
Description: US Web Design Standards WP Theme
Version: 20180828
*/
@import "variables";
@import "animate";
@import "grid";
@import "spinner";
@import "./node_modules/uswds/src/stylesheets/uswds";

// Functions
$browser-context: 17; // Default

@media (max-width: 768px) {
    body {
        font-size: 16px!important;
    }
}

@function em($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em;
}

// global theme mixins
@mixin BeforeSlash( $color: $uhc-gold ) {
    position: relative;
    padding-left: 90px;
    &:before {
        content: ' ';
        position: absolute;
        width: 30px;
        height: 60px;
        left: 20px;
        top: 50%;
        transform: translateY(-50%) skew(-33.75deg);
        background: $color;
    }
}
.gold-slash {
    @include BeforeSlash();
    color: $uhc-blue;
    line-height: 1;
}
.blue-slash {
    @include BeforeSlash(#002855);
}
.white-slash {
    @include BeforeSlash(#fff);
}
@mixin StyledList( $bulletcolor: $uhc-gold, $bulletcontent: '\f0c8' ) {
    list-style: none;
    list-style-position: outside;
    margin-top: 0;
    padding-left: 1rem;
    li {
        line-height: 1.2;
        padding-bottom: 12px;
        position: relative;
        padding-left: 18px;
        border-bottom: 1px solid #d8d8d8;
        &:before {
            position: absolute;
            font-family: FontAwesome;
            left: -1rem;
            font-size: 16px;
            top: 0px;
            content: $bulletcontent;
            color: $bulletcolor;
        }
    }
}
ul.styled {
    @include StyledList;
    z-index: 55555;
}
blockquote {
    border-left: 1px solid $uhc-blue;
    margin: 1em 0;
    padding: 0 2em;
    color: $uhc-blue;
    font-size: 1.1em;
}
@import "fonts";
// typography
h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
    line-height: 1;
}
h1, h2 {
    font-weight: bold!important;
    &.bold {
        font-weight: bold!important;
        &.gold-slash {
            font-weight: bold!important;
        }
    }
}
.grey-text {
    color: #757575;
}
.text-center {
    text-align: center;
}
.text-larger {
    font-size: 1.5em;
}
@mixin line-break ( $color ) {
    width: 110px;
    background-color: $color;
    height: 3px;
    margin-bottom: 45px;
}
.blue-line {
    @include line-break($uhc-blue);
}
.gold-line {
    @include line-break($uhc-gold);
}
// list styles
.no-bullet {
    list-style-type: none;
    padding-left: 0;
}
.divider {
    @extend .no-bullet;
    li {
        border-bottom: 1px solid #ececec;
        padding-bottom: 1rem;
    }
}
// header
.usa-header-basic {
	border-bottom: none;
    background-color: #fff;
    box-shadow: 0px 1px 28px rgba(0, 0, 0, 0.1), 0px 1px 5px rgba(0, 0, 0, 0.05);
    position: relative;
    @media (max-width: 1050px) {
        position: static;
    }
	.usa-nav-container {
		max-width: 100%;
		height: $navbar-height;
	}
	.usa-navbar {
		height: $navbar-height;
		padding-left: 10px;
		padding-right: 10px;
		@media (max-width: #{$nav-width - 1}) {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
	        border-bottom: none;
		}
		.usa-logo {
			margin-top: 0px;
			margin-bottom: 0px;
			margin-left: 0px;
            width: 360px;
            height: 100%;
			@media (max-width: #{$nav-width - 1}) {
				max-width: calc(100% - 60px);
			}
			img {
				vertical-align: middle;
			    width: 100%;
			    height: auto;
			}
		}
    }
    &.static {
        position: static;
    }
}
// navbar
nav.usa-nav {
	padding-top: 0px;
	padding-left: 0px;
	.usa-nav-inner {
		padding-left: 0;
		padding-right: 0;
	}
	.usa-nav-close {
		color: $color-primary;
		margin-bottom: 3em;
	}
	a {
		font-family: $helvetica !important;
		i.fa {
		    vertical-align: middle;
		}
	}
	@media (min-width: $nav-width) {
		height: $navbar-height;
		display: flex;
		align-items: center;
		.usa-nav-primary {
			margin-top: 0;
			display: flex;
		    flex: 1;
		    align-items: center;
			li {
				position: relative;
			    transform: skew(-33.75deg, 0deg);
			    border-right: 1px solid #859cb3;
			    -webkit-font-smoothing: antialiased;
	    		padding: 0px 8px;
	    		transition: $global-transition;
	    		&:last-child {
	    		    border-right: none !important;
	    		}
	    		&:hover {
	    			background-color: $uhc-gold;
	    		}
			    a {
			    	transition: background-color .3s;
				    font-size: 16px;
				    color: $uhc-blue;
				    display: block;
				    cursor: pointer;
				    padding: 16px;
			        transform: skew(33.75deg, 0deg);
			        letter-spacing: -0.25px;
			        &:hover {
			            span {
	    			        border: none !important;
			            }
	    			}
			    }
			}
			li.phone-number {
			    border-right: none;
			    a {
			        position: relative;
			        padding-left: 77px;
			        font-size: 38px;
			        &:before {
			            content: ' ';
			            width: 45px;
			            height: 45px;
			            background-image: url('img/call-icon-gold.svg');
			            position: absolute;
                        background-size: 100%;
                        left: 16px;
                        top: 50%;
                        transform: translateY(-50%) skew(33.75deg, 0deg);
                        transition: $global-transition;
			        }
                }
                &:hover {
                    background-color: rgba(0,0,0,.05);
                    a:before {
                        filter: brightness(105%);
                    }
                }
                &:active {
                    background-color: rgba(0,0,0,.035);
                }
			}
		}
	}
}
// mobile menu
.usa-overlay.is-visible {
    opacity: 0.5;
    z-index: 6999;
    background-color: $uhc-blue;
}
@media (max-width: 1050px) {
    .usa-nav.is-visible {
        z-index: 10001;
        background-color: $uhc-blue;
        padding-right: 0px;
        display: block;
        border-left: none;
        a {
            color: #fff !important;
            font-weight: bold;
            text-decoration: underline;
            padding: 12px 0px 12px 25px;
            border-bottom: 1px solid #0033A0;
        }
        .usa-nav-primary > li {
            border-top: none !important;
        }
        .usa-nav-link:hover {
            color: $uhc-blue!important;
            text-decoration: no-underline!important;
        }
        .usa-accordion {
            .usa-nav-link {
                font-weight: 700!important;
                text-decoration: underline!important;
                &:hover {
                    background-color: white!important;
                    color: $uhc-blue!important;
                }
            }
            .usa-accordion-button {
                transform: skew(0, 0)!important;
                color: white!important;
                &[aria-expanded=true] {
                    background-color: white!important;
                }
            }
        }
        .usa-nav-submenu {
            background-color: $uhc-blue;
            li a:hover {
                background-color: white!important;
                color: $uhc-blue!important;
            }
        }
    }
}
// mobile menu toggle button
.usa-menu-btn, .usa-nav-close {
	background-color: $uhc-gold;
    border-radius: 100%;
    box-shadow: $box-shadow-button;
    color: #FFF !important;
    max-width: 4rem;
    transition: $global-transition;
    &:hover {
        background-color: lighten($uhc-gold, 5%);
    }
}
.usa-nav-close {
	width: 4rem;
    height: 4rem;
	margin-top: 1rem;
    margin-right: 10px;
}
.fixedmobile{
    position:fixed;
    top: 10px;
    right: 10px;
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: $uhc-gold;
    border-radius: 100%;
    z-index: 10005;
}
// footer
footer.page-footer {
    padding-top: 0;
    background-color: $uhc-blue;
    margin-top: 20px;
    .footer-copyright{
        height: auto!important;
        line-height: 1.3;
        background-image: url(img/pattern-grid.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        &::before {
            border: 3px solid $uhc-blue;
            width: 100%;
            content: "";
            position: absolute;
            top: 0.8em;
        }
        & > .container > .row {
            padding: 8% 0 0;
            @media (min-width: 600px) {
                padding: 2% 0 0;
            }
        }
        .footer-white{
            background-image: url(img/gold-pattern.svg);
            background-position: top center;
            background-size: 1900px;
            background-repeat: repeat-x;
            background-color: white;
            & > .container {
                padding-top: 30px;
                padding-bottom: 10px;
            }
            @media (max-width: $breakpoint-ipad) {
                background-image: none;
                background-color:#fff;
            }
            nav ul li{
                @media (max-width: $breakpoint-ipad) {
                    background-image: none;
                    background-color:#fff;
                }
                @media (max-width: $breakpoint-small-tablet) {
                    border-right: none;
                    width: 100%;
                }
            }
            div.m7{
                padding-top:6px;
                padding-left:20px;
                @media (max-width: $breakpoint-small-tablet) {
                        padding-left: 0;
                }
            }
        }
        h5, p, ul, ol, div {
    	    color: #fff;
    	}
    	h5 {
    	    font-size: em(24);
            margin-bottom: 0px;
            font-weight: 700;
            font-family: $helvetica-condensed;
    	}
    	a {
            color: $uhc-gold;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &:visited {
                color: $uhc-yellow-green;
            }
        }
        .footer-column {
        	line-height: 1.5;
        	@media (min-width: $large-screen) {
        	    padding-right: 20px;
        	}
            ul {
        	    list-style-type: none;
        	    padding-left: 0;
            }
        }
    }
    img.logo {
        width: 100%;
        height: auto;
        max-width: 350px;
    }
    input[type="search"] {
        border: 1px solid $uhc-gold;
    }
    .calling-card {
    	@extend .card;
    	font-size: 38px;
	    color: $uhc-gold !important;
	    background-color: rgba(245, 245, 255, 0.1)!important;
	    margin-top: 4%;
	    display: flex;
	    width: 340px;
	    height: 90px;
	    max-width: 100%;
	    align-items: center;
	    justify-content: center;
	    text-decoration: none;
	    border-radius: 6px;
	    &:hover {
	        text-decoration: none!important;
	        background-color: white!important;
	        opacity: 1!important;
	    }
	    &:visited {
	        color: #c0a83f !important;
	    }
	    img {
	    	max-width: 45px;
    		margin-right: 12px;
	    }
	    @media (max-width: $small-screen) {
	        font-size: 28px;
	        img {
	            max-width: 32px;
	        }
	    }
	    @media (min-width: $medium-screen) {
	        float: right;
        }
        & + p {
            margin-top: 1em!important;
        }
    }
    @media #{$medium-and-down} {
        input[type="text"] {
            max-width: 320px !important;
        }
    }
}
#footer .m4 > .footer-column:nth-of-type(2) p {
    margin-bottom: 0.25em;
}
// sidebar
aside {
    background-color: #fff;
    position: relative;
    padding-bottom: 1%;
    @media (min-width: 1200px) {
        margin-top: -230px;
    }
    @media (max-width: $medium-screen) {
        top: 0;
        border: none;
        margin-top: 3em;
    }
	.sidebar-widget {
		ul {
			list-style-type: none;
		}
	}
	.sidebar-card {
	    @extend .card;
	    max-width: 484px !important;
        position: relative;
        // padding-bottom: 230px;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 6px;
        border: none!important;
        transform: none!important;
        // & > .widget_text:nth-of-type(1) {
        //     padding-bottom: 2em;
        //     padding: 0!important;
        //     & > div {
        //         padding: 6px 32px;
        //         position: relative;
        //     }
        // }
        .housecall-bg{
            background-image: url(/wp-content/themes/uhc-uswds/img/housecall_background.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
        }
        & > .widget_text:nth-of-type(2) > .textwidget {
            & > div p {
                margin-top: 0;
                line-height: 1em;
                font-size: 1.2em;
                @media (min-width: 1450px) {
                    font-size: 1.5em;
                }
            }
        }
        @media (max-width: #{$large-screen - 1}) {
            margin-right: auto !important;
            margin-left: auto !important;
        }
        img.card-image-full {
            position: absolute;
            top: -105px;
            width: 100%;
            max-width: calc(100% + 4px) !important;
        }
        .textwidget {
        //    padding: 6px 32px;
        //    @media (max-width: $small-screen) {
        //        padding: 6px 16px;
        //    }
            .lead {
                font-weight: 700;
                font-family: $helvetica-condensed;
                font-size: 1.8em;
                line-height: 1.2;
                color: #757575;
                margin-bottom: 0!important;
                @media (min-width: 1200px) and (max-width: 1450px) {
                    font-size: 1.6em;
                    & + p {
                        font-size: 1.2em!important;
                        & + a {
                            font-size: 1.4em!important;
                        }
                    }
                }
                & + p {
                    font-size: 1.4em;
                    margin: 0;
                    line-height: 1.2em;
                    & + a {
                        text-decoration: none;
                        color: $uhc-gold;
                        font-family: $helvetica;
                        font-weight: 700;
                        font-size: 1.6em;
                        margin-top: 0.5em;
                        display: block;
                        @media (min-width: $medium-screen) {
                            font-size: 1.8em;
                        }
                        &::after {
                            content: "";
                            background-repeat: no-repeat;
                            width: 1.6em;
                            height: 2.5em;
                            display: inline-block;
                            float: right;
                            margin-top: -0.9em;
                        }
                    }
                }
            }
            ul {
                @include StyledList( $uhc-gold );
                font-family: $helvetica;
                font-size: 20px;
                color: #000;
            }
        }
        .healthgrade-logo {
            width: 298px;
            display: block;
            margin: 0 auto 18px auto;
        }
        .healthgrade-stars {
            background-color: #687385;
            border-radius: 3px;
            padding: 10px 20px;
            text-align: center;
            color: #fff;
            font-size: 32px;
            max-width: 298px;
            margin: 0 auto;
            box-shadow: $box-shadow;
            transition: $global-transition;
            &:hover{
                -webkit-box-shadow: 0 20px 30px rgba(0,0,0,.1);
                box-shadow: 0 20px 30px rgba(0,0,0,.1);
                -webkit-transform: translate(-2px,-2px);
                transform: translate(-2px,-2px);
            }
        }
        a.hg-link{
            text-decoration: none;
        }
        .sidebar-blue-text {
            text-align: center;
            font-size: 32px;
            font-weight: 700;
                font-family: $helvetica-condensed;
            color: #1162a9;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
	    .sidebar-card-title, .sidebar-card-content {
	        padding: 7.5%;
	    }
	    .sidebar-card-title {
	        background: #f0b220;
	        font-size: 18px;
	    }
	    .sidebar-card-content {
	        background: #fff;
	        ul {
	            @include StyledList;
	        }
	    }
	}
	iframe {
        max-width: 100%;
    }
	// widget title
	h5 {
        font-family: $helvetica;
        color: $uhc-blue;
        padding: 16px 0 24px;
        border-bottom: 1px solid #a8b4cc;
        margin-bottom: 30px;
        font-size: 21px;
        line-height: 1.25;
        span {
            font-family: $helvetica;
            display: block;
            text-transform: uppercase;
            font-size:27px;
            font-weight: bold;
        }
    }
	.sb-links {
        hr{
            margin: 30px 0;
            border-top: 1px solid #a8b4cc;
        }
        ul {
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                transition: $global-transition;
                background-color:#000;
                &:hover{
                    box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    transform: translate(-2px,-2px);
                }
               .oncology {
                    background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvcancercenter.com/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_02.jpg);
                }
                .ortho {
                    background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvorthocenter.com/wp-content/uploads/2018/01/ortho-page-header-images3.jpg);
                }
                .emergency {
                    background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://uhcemergencyroom.com/wp-content/uploads/2018/01/uhc-er-header3c.jpg);
                    background-position:bottom;
                }
                a {
                    padding:8% 5%;
                    text-decoration: none;
                    display: block;
                    border: 1px solid #16375c;
                    font-family: $iowan-old;
                    font-size:35px;
                    text-transform:uppercase;
                    line-height: 1.2;
                    background-size: 100%;
                    color: #fff;
                    text-shadow: 1px 1px 0 #18385d;
                    background-blend-mode: multiply;
                    span{
                        font-weight:300;
                        font-size: 13px;
                        display: block;
                        letter-spacing: 3px;
                        font-family: $helvetica;
                    }
                    @media (max-width: $small-screen) {
                        font-size: 28px;
                        word-break: break-word;
                    }
                }
            }
        }
    }
    .card {
        @media (max-width: #{ $nav-width - 1 }) {
            border: none;
            &:hover {
                box-shadow: none;
            }
        }
    }
    .link-wrap {
        position:relative;
        display: block;
        width: 100%;
        iframe {
            z-index: 2;
        }
        img {
            display: block;
            margin: 0 auto;
            box-shadow: $box-shadow-large;
        }
    }
    .blocker {
        position:absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
// end aside
}
aside.post-sidebar {
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border: 1px solid #a8b4cc;
    @media (max-width: #{ $nav-width - 1 }) {
        padding: 0;
        border: none;
    }
}
// section
.usa-section {
	padding-top: 50px;
	padding-bottom: 50px;
}
// blogs section
.section-blogs {
    @extend .grey-bg;
}
// blog archive
body.blog{
    .ind-blog {
        @media (min-width: $medium-screen) {
            padding-right: 3.5%;
        }
        .card{
            margin: .5rem 2% 2rem 0;
        }
    }
    .sb-links{
        border: none;
    }
}
// single template
.single, .search {
    article {
        padding-right: 3.5%;
        padding-bottom: 50px;
        p strong {
            font-family: $helvetica;
            font-weight: bold;
        }
    	// lists
    	ul {
    		@include StyledList;
    	}
        h2 {
            font-family: $helvetica;
            font-weight: bold;
            letter-spacing: 0px;
            text-transform: none;
            font-size: 32px;
            line-height: 38px;
        }
        ol {
            padding-left: 40px;
            counter-reset: list;
            li {
                font-weight: bold;
                list-style: none;
                position: relative;
                &:before {
                    counter-increment: list;
                    content: counter(list) ") ";
                    position: absolute;
                    left: -40px;
                    top: -4px;
                    font-size: 32px;
                    font-family: $iowan-old;
                }
                h2 {
                    font-family: $helvetica;
                    font-weight: bold;
                }
                p {
                    font-size: 17px;
                    font-family: $helvetica;
                }
            }
        }
        .card {
            padding: 32px;
            margin-top: 64px;
            margin-bottom: 64px;
            font-family: $iowan-old;
            color: $uhc-blue;
            font-size: 24px;
            box-shadow: $box-shadow-large;
            &:hover {
                transform: none !important;
            }
        }
    }
}
// post meta
.post-meta {
	font-size: 0.8em;
	text-transform: uppercase;
}
// pagination
ul.pagination {
	list-style-type: none;
	li {
		display: inline-block;
		margin-right: 10px;
	}
}
body.single{
    .page-heading{
        background-color: rgba(0, 92, 181, 0);
    }
}
body {
    // page styles
    .page-heading {
        padding: 7% 0 8%;
        position: relative;
        background-size: cover;
        background-position: top left;
        background-repeat: no-repeat;
        .container {
            position:relative;
            z-index: 1;
        }
        h1, h2, p {
            color: #fff;
            max-width: 767px;
            text-shadow: 1px 1px 8px rgba(0, 0, 0, .5);
        }
        h1 strong {
            font-weight: bold!important;
            font-size: 1.5em;
        }
        h1 {
            line-height: 0.95!important;
            font-weight: bold!important;
        }
        h2 {
            font-weight: bold!important;
        }
        p {
            color: #fff;
            margin: 0 0 8px 0 !important;
            text-shadow: 1px 1px 8px rgba(0, 0, 0, .5);
            font-size: 22px;
            strong {
                font-size: 1.3em;
            }
        }
        a {
            text-shadow: none;
        }
        .usa-button {
            font-size: 24px;
        }
    }
    .blog-heading {
        background-position: center;
        position: relative;
        overflow: hidden;
        padding: 12.5% 0;
        .blog-titlebg {
            position: absolute;
            background-color: rgba(104, 117, 99, 0.62);
            height: 1500px;
            width: 1250px;
            top: -106%;
            transform: rotate(33.75deg);
            left: -7%;
            @media (max-width: $large-screen) {
                left: -24%;
            }
        }
    }
}
// diagonal / angle div
.angle-post {
    bottom: 0;
    left: 0;
    right: 0;
    @media (max-width: 800px) {
        display: none;
    }
    svg {
        height: 200px;
        width: 100%;
    }
}
// TEXT STYLES
// typography
body {
    font-family: $helvetica;
    font-size: em(17);
    line-height: 31px;
    color: $uhc-black;
}
h1.alt {
    font-family: $helvetica-condensed;
    font-size: em(44);
    line-height: 55px;
    font-weight: normal;
    background-color: $uhc-black;
    color: $uhc-white;
    text-transform: uppercase;
}
h1 {
    font-family: $helvetica-condensed;
    font-size: em(58);
    line-height: 1;
    font-weight: normal;
    color: $uhc-blue;
    letter-spacing: -2px;
    text-transform: uppercase;
    @media (max-width: $breakpoint-phone) {
        font-size: em(38);
        line-height: 1;
        letter-spacing: 0px;
    }
}
h2 {
    font-family: $helvetica-condensed;
    text-transform: uppercase;
    font-size: 55px;
    color: $uhc-blue;
    // font-weight: 400;
    letter-spacing: -2px;
    @media (max-width: $breakpoint-phone) {
        font-size: 34px;
    }
}
h3 {
    font-family: $helvetica;
    font-size: em(28);
    line-height: 34px;
    color: $uhc-blue;
    font-weight: 300;
    @media (max-width: $breakpoint-phone) {
        font-size: em(24);
        line-height: 1;
        letter-spacing: 0px;
    }
}
h4 {
    font-family: $helvetica;
    font-size: em(30);
    line-height: 37px;
    color: $uhc-black;
    font-weight: 700;
}
h5 {
    font-family: $helvetica;
    font-size: em(28);
    line-height: 34px;
    color: $uhc-black;
    font-weight: 400;
}
// intro paragraph
.intro-p {
    font-size: em(28);
    line-height: 1.5;
    color: $uhc-blue;
    @media (max-width: $small-screen) {
        font-size: em(18);
    }
}
// main
.blog main {
    padding-bottom: 50px;
}
// button
a.usa-button, button.usa-button, input[type="submit"].usa-button {
    font-family: $helvetica !important;
    font-size: 17px;
    color: #fff;
    background-color: $uhc-gold;
    transition: $global-transition;
    width: auto;
    &:hover {
        background-color: $uhc-yellow-green;
    }
    &:active {
        background-color: #e8d27c;
    }
}
.usa-button-flat {
    @extend .usa-button;
    border: 2px solid #fff;
    font-family:  $helvetica;
    box-shadow: none;
    background: transparent !important;
    color: #343434;
    cursor: pointer;
    &:hover {
        border: 2px solid #fff;
        background: rgba(0,0,0,.1) !important;
        box-shadow: none;
    }
}
// elements and components
.card {
    border: 1px solid #ececec;
    transition:  $global-transition;
    position: relative;
    background-color: #fff;
    max-width: 100%;
    .title {
        font-family: $helvetica;
        font-weight: bold;
        padding: #{$card-padding / 4} #{$card-padding * 2};
        background: $uhc-gold;
        color: #fff;
        max-width: 100%;
    }
    .content {
		padding: $card-padding #{$card-padding * 2};
		max-width: 100%;
		p:first-child {
		    margin-top: 0;
		}
	}
	.usa-button, .usa-button-flat {
	    display: inline-block;
        color: #fff;
        text-align: center;
        padding: 6px 25px;
	}
    &:hover {
    	box-shadow: $box-shadow-large;
    	transform: translate(-2px, -2px);
    }
}
.card.simple {
    padding: $card-padding;
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}
.card.no-hover {
    box-shadow: $box-shadow-large;
	transform: translate(-2px, -2px);
    &:hover {
    	box-shadow: $box-shadow-large !important;
    }
}
.card.callout {
    padding: #{$card-padding * 2};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    font-family: $iowan-old;
    color: $uhc-blue;
    font-size: 24px;
    p {
        margin: 0;
    }
    .card-icon {
        max-width: 120px;
        margin-right: 16px;
        float: left;
    }
    @media (max-width: $small-screen) {
        flex-wrap: wrap;
        display: block;
        padding-top: 64px !important;
        padding-bottom: 16px !important;
        .card-icon {
            float: none;
            position: absolute;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
// blog tease
a.tease {
	text-decoration: none !important;
	width: 100%;
    border-radius: 6px;
	@media (max-width: $small-screen) {
	    min-height: 0px;
	    height: auto;
	    margin: 0 auto;
	    .content {
    	    font-size: 18px;
    	}
	}
	@media (min-width: 992px) {
	    min-height: 320px;
	}
	img {
        margin: -1px;
        max-width: calc(100% + 2px);
        border-radius: 6px;
    }
    .featured-img {
        border-radius: 6px 6px 0 0;
        width: 100%;
        max-width: calc(100% + 2px);
        height: 160px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .featured-img-link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
        }
    }
	&:hover {
		text-decoration: underline !important;
	}
	.content {
	    font-size: 24px;
	}
}
// doctor cards
#specialists {
    padding-top: 2em;
    & > .col {
        max-width: 400px;
        @media (max-width: 845px) and (max-width: 992px) {
            margin: 0 auto;
        }
    }
}
.doc-card {
    display: flex;
    flex: 1;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 6px;
    max-width: 435px;
    margin: 0 auto;
    img.doc-headshot {
    	height: 100%;
        padding: 0.5em;
        display: inline-block;
        float: left;
        max-width: 120px;
        width: 100%;
    }
    .doc-blue {
        background-color: rgba(207, 233, 244, 0.6);
        width: 100%;
        height: 115px;
        position: relative;
        border-radius: 5px 5px 0 0;
	    h5 {
            font-size: 21px;
            font-family: $helvetica;
            font-weight: 600;
            top: 0;
            color: $uhc-blue;
            bottom: 0;
            margin: 0;
            line-height: 1.1;
            @media (min-width: 600px) {
                font-size: 23px;
            }
            span {
                font-size: 0.6em;
                font-weight: 600;
                color: $uhc-blue;
            }
		}
	}
	.bottom-doc {
	    max-width: 100%;
	    padding: 32px;
	    margin-bottom: 3em;
	    p:first-child {
    	    margin-top: 0px;
    	}
	}
	a {
        width: 90%;
        text-align: center;
        padding: 0.5em 0;
        background-color: $uhc-gold;
        text-decoration: none;
        transition: $global-transition;
        color: $uhc-blue;
        border-radius: 6px;
        margin: 0 auto;
        margin-bottom: 1em;
        font-weight: 700;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        &:hover {
            opacity: 0.9;
            transition: $global-transition;
            color:$uhc-blue;
        }
	}
}
// sections and background
.grey-bg {
    background-color: #e8eaec;
    padding-bottom: 5%;
}
/// map style
.map-container {
    .infowindow {
        position: absolute;
        display: none;
        top: 50%;
        width: 474px;
        left: 50%;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 2;
        margin-left: -601.5px;
        margin-top: -200px;
        padding: 1.5em;
        font-weight: 300;
        font-size: 24px;
        border-radius: 6px;
        .infowindow-margin {
            p:nth-of-type(2) {
                margin: 0!important;
                & > a {
                    margin: 0;
                }
            }
        }
        img {
            height: 46px;
            @media (min-width: 500px) {
                height: 56px;
            }
        }
        .usa-button {
            width: 100%;
            text-align: center;
            padding: 1em 0;
            background-color: $uhc-gold;
            text-decoration: none;
            transition: $global-transition;
            color: $uhc-blue;
            border-radius: 6px;
            margin: 0 auto;
            margin-bottom: 0;
            font-weight: 700;
            display: block;
            &:hover {
                opacity: 0.9;
                transition: $global-transition;
                background-color: $uhc-gold;
                color: $uhc-blue;
            }
        }
        @media (max-width: 1270px) {
            top: 18%;
            left: 10%;
            margin-left: 0;
            margin-top: 0;
            width: 474px;
        }
        @media (max-width: $small-screen) {
            display: none!important;
        }
        @media (min-width: 1320px) {
            left: 45%;
        }
    }
    #map, #mapInd {
        height: 400px;
        position: relative;
        z-index: 1;
        background-image: url('/wp-content/uploads/2021/04/uhc-map-blurred.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        #showMap {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: $helvetica-condensed!important;
            font-size: 1.2em;
            padding: 0.75em 2em;
            max-width: 250px;
        }
    }
    &.activated {
        #map, #mapInd {
            height: 600px;
        }
        .infowindow {
            display: block;
        }
    }
}

// single doctors
.single-doctors {
    .post-type-doctors {
        margin-top: 32px;
        padding-bottom: 50px;
        h3 > p {
            margin-top: 0.15em;
        }
        .row .m7 {
            h1 {
                margin-bottom: 0;
            }
            & > p:first-of-type {
                margin: 0;
                font-weight: 600;
                color: $uhc-blue;
                font-size: 1.2em;
            }
        }
        .row .col:first-of-type div div {
            @media (min-width: 992px) {
                .doc-svg {
                    bottom: 8px!important;
                }
            }
        }
    }
}
// utility classes
.padding-5-0 {
    padding-top: 5%;
    padding-bottom: 5%;
}
.no-mobile {
    @media (max-width: #{$medium-screen - 1}) {
        display: none;
    }
}
.yes-mobile {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: block !important;
    }
}
.yes-mobile-inline {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: inline !important;
    }
}
.yes-mobile-flex {
    display: none !important;
    @media (max-width: #{$medium-screen - 1}) {
        display: flex !important;
    }
}
.mobile-block-center {
    @media (max-width: #{$medium-screen - 1}) {
        display: block;
        margin: 0 auto;
    }
}
.mobile-nav-link {
    display: none !important;
    @media (max-width: #{$nav-width - 1 }) {
        display: block !important;
    }
}
.negativemargtop {
    @media (min-width: $large-screen) {
        margin-top: -243px;
    }
}
.float-right-no-mobile {
    float: right;
    @media (max-width: $medium-screen) {
        float: none;
    }
}
.float-left-no-mobile {
    float: left;
    @media (max-width: $medium-screen) {
        float: none;
    }
}
// margin classes
.margin-left-no-mobile {
    margin-left: 16px;
    @media (max-width: $medium-screen) {
        margin-left: 0;
    }
}
.margin-right-no-mobile {
    margin-right: 16px;
    @media (max-width: $medium-screen) {
        margin-right: 0;
    }
}
.flex-end-self {
    align-self: flex-end;
}
.et_monarch .et_social_networks ul.et_social_icons_container {
    display: flex !important;
    align-items: center;
    @media (max-width: $small-screen) {
        justify-content: space-between;
    }
    li {
        width: auto !important;
    }
}
// top bar
#topbar {
    background: linear-gradient(to right, #005eb8 0%, #002855 17%, #002855 95%, #002855 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005eb8', endColorstr='#002855',GradientType=1 );
    position: relative;
    z-index: 1;
    .top-container {
        width: 90% !important;
        min-height: 75px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-height: 56px;
        color: #fff;
        font-size: 18px;
        line-height: 1;
        img {
            margin-right: 1rem;
        }
        .usa-button, .usa-button-flat {
            font-size: 16px;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}
.uhc-bar {
    background-color: #17375c;
    text-align: right;
    background-image: url(img/pattern-grid.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
    @media (max-width: #{$nav-width -  1}) {
        display: none;
    }
    .usa-nav-container {
        height: auto;
        padding: 5px 55px;
    }
    a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}
.notch {
    width: 48px;
    height: 48px;
    transform: rotate(45deg);
    background: #fff;
    position: absolute;
    top: -24px;
    left: 0px;
    @media (max-width: $small-screen) {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
    }
}
// search field
.usa-search.usa-search-small {
    margin-bottom: 1rem;
    input[type="search"] {
        width: calc(100% - 45px);
        max-width: 100%;
        height: 35px;
    }
    [type="submit"] {
        background-color: $uhc-gold;
        height: 35px;
    }
}
.usa-search {
    max-width: 18em;
}
// include custom site styles
@import "custom";
#bitnami-banner .bitnami-corner-image-div .bitnami-corner-image{
    display: none!important;
}
p.no-p{
    display: inline-block;
}
// new blog styles, and sidebar styles, to copy over past this point
.blogarchive {
    padding: 11.5% 0 15%;
    position: relative;
    z-index: 9;
    h2 {
        color: #fff;
        max-width: 615px;
        margin-top: 0;
        margin-bottom: 10px;
    }
}
body.blog {
    .card.archive-card.grid-item:nth-of-type(1) {
        width: 100%!important;
        z-index: 9;
        .featured-img{
            height: 275px;
            .featured-img-link{
                display: block;
                height: 100%;
                width: 100%
            }
        }
        h2 {
            font-size: 32px;
            font-family:'Helvetica Neue condensed',helvetica,arial,sans-serif;
            text-transform: uppercase;
            line-height: 1.1;
            a {
                color: $uhc-med-blue;
            }
        }
        &:before {
            content: 'Featured';
            background-color: #7670e1;
            color: #f1eee7;
            position: absolute;
            padding: 2% 4%;
            left: 0;
            top: 0;
            text-transform: uppercase;
            font-weight: 300;
            letter-spacing: 4px;
            font-size: 14px;
        }
    }
    .ind-blog{
        margin-top: -140px;
        h2 {
            font-family:'Helvetica Neue ',helvetica,arial,sans-serif;
            text-transform: none;
        }
        @media #{$medium-and-down} {
           margin-top: 0;
        }
    }
}
body.paged-2,body.paged-3,body.paged-4 {
    .card.archive-card.grid-item:nth-of-type(1) {
    width: 31.333% !important;
     @media #{$medium-and-down} {
       width: 48% !important;
    }
    @media #{$small-and-down} {
       width: 100% !important;
    }
    .featured-img {
        height: 160px;
        .featured-img-link {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
        }
    }
    h2 {
        font-size: 20px;
        font-family:$helvetica;
    }
    &:before {
        content: none;
    }
    }
    .ind-blog {
        margin-top: 0px;
    }
}
.flex-blog {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 1em;
}
.ind-blog {
    img {
        max-width: 100%;
    }
    .archive-card {
        margin-left: 0 !important;
        margin-right: 2%;
        width: 31.333%!important;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 1.25em;
        @media #{$medium-and-down} {
            width: 48% !important;
        }
        @media #{$small-and-down} {
            width: 100% !important;
        }
        h2 {
            font-size: 20px;
            letter-spacing: 0;
            margin: 0 0 7px;
                a {
                    color: $uhc-blue;
                    text-decoration: none;
                    &:visited {
                        color: $uhc-blue;
                    }
                }
        }
        &:hover {
            .readmore-archive {
                img {
                    transform: rotate(0deg);
                }
            }
        }
        .readmore-archive {
            display: block;
            padding: 1% 3% 1% 5%;
            border-top: 1px solid #e3e3e3;
            color: #545454;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-decoration: none;
            font-family: $helvetica;
            background-color: #f6f6f6;
            transition: $global-transition;
            text-align: right;
            img {
                transition: $global-transition;
                transform: rotate(-45deg);
            }
            &:hover {
                opacity: 0.8;
                transition: $global-transition;
            }
        }
    }
}
.featured-img {
    width: calc(100% + 2px);
    height: 160px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .featured-img-link {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
    }
}
.sb-links {
        hr{
            margin: 30px 0;
            border-top: 1px solid #a8b4cc;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                transition: $global-transition;
                background-color:#000;
                &:hover {
                    -webkit-box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    box-shadow: 0 20px 30px rgba(0,0,0,.1);
                    -webkit-transform: translate(-2px,-2px);
                    transform: translate(-2px,-2px);
                }
               .oncology {
                    background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvcancercenter.com/wp-content/uploads/2017/02/UHC-site-wvu-version-web-export-wo-text-sliced_02.jpg);
                }
                .ortho {
                    background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://wvorthocenter.com/wp-content/uploads/2017/04/ortho-page-header-images3.jpg);
                }
                .emergency {
                    background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)),url(https://uhcemergencyroom.com/wp-content/uploads/2018/01/uhc-er-header3c.jpg);
                    background-position: bottom;
                }
                a {
                    padding: 8% 5%;
                    text-decoration: none;
                    display: block;
                    border: 1px solid #16375c;
                    font-family: $iowan-old;
                    font-size: 35px;
                    text-transform: uppercase;
                    line-height: 1.2;
                    background-size: 100%;
                    color: #fff;
                    text-shadow: 1px 1px 0 #18385d;
                    background-blend-mode: multiply;
                    span {
                        font-weight: 300;
                        font-size: 13px;
                        display: block;
                        letter-spacing: 3px;
                        font-family: $helvetica;
                    }
                    @media (max-width: $small-screen) {
                        font-size: 28px;
                        word-break: break-word;
                    }
                }
            }
        }
}
.post-sidebar h5 span {
    font-family: $helvetica;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
    font-size: 27px;
}
.mywvuchart-card {
    padding: 0!important;
    margin-top: 2em!important;
    border-radius: 6px;
    margin-bottom: 6em!important;
    @media (min-width: 600px) {
        margin-bottom: 2em!important;
    }
    .sidebar-widget.mywvuchart {
        padding: 0;
        display: block;
        a {
            display: block;
            background-color: $uhc-blue;
            color: white;
            text-decoration: none;
            font-weight: 700;
            text-align: center;
            font-size: 1.5em;
            padding: 0.5em 0;
            transition: $global-transition;
            border-radius: 6px;
            &:hover {
                opacity: 0.9;
                transition: $global-transition;
            }
            img {
                width: 50%;
                padding-top: 6px;
            }
        }
        .textwidget {
            padding: 0;
        }
        img {
            border-radius: 5px 5px 0 0;
            width: 100%;
        }
    }
    & > div:nth-of-type(2) {
        margin-top: -3em;
        background-image: none!important;
    }
    & > div:nth-of-type(3) {
        padding: 1em!important;
        & > div {
            border-radius: 6px;
            display: inline-block;
            width: 100%;
        }
    }
}
//Slideshow
ul#slides {
    position: relative;
    height: 7em;
    margin-bottom: 0;
    @media (min-width: 600px) {
        height: 6em;
    }
}
#slides .slide {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: 5em;
    padding: 0 2em!important;
    font-size: 0.75em;
    line-height: 1.3!important;
    border-bottom: none!important;
    margin-top: 2.5em;
    color: $uhc-blue;
    background: #fff;
    @media (min-width: 600px) {
        height: 4em;
        font-size: 18px;
    }
    &:before {
        content: "";
        top: 0;
        left: 0;
    }
    &:nth-child(2) {
        opacity: 0;
        -webkit-animation-delay: 6s;
        animation-delay: 6s;
        -moz-animation-delay: 6s;
        -o-animation-delay: 6s;
    }
    &:nth-child(3) {
        opacity: 0;
        -webkit-animation-delay: 12s;
        animation-delay: 12s;
        -moz-animation-delay: 12s;
        -o-animation-delay: 12s;
    }
    &:nth-child(4) {
        opacity: 0;
        -webkit-animation-delay: 18s;
        animation-delay: 18s;
        -moz-animation-delay: 18s;
        -o-animation-delay: 18s;
    }
    & > div {
            width: 45px;
            height: 45px;
            float: left;
            margin-right: .5em;
            margin-bottom: 6em;
        .icon {
            height: 100%;
            width: fit-content;
        }
    }
}
.fade {
-webkit-animation: fade 24s infinite;
animation: fade 24s infinite;
-moz-animation: fade 24s infinite;
-o-animation: fade 24s infinite;
}
@-webkit-keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
@-moz-keyframes fade{
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
@keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
    }
@-o-keyframes fade {
    0% {opacity: 0}
    10% {opacity: 1}
    24% {opacity: 1}
    30% {opacity: 0}
    100% {opacity: 0}
}
.article-body ul li ul {
	margin-top: 1em!important;
	margin-bottom: 0;
}
.article-body ul li ul li::before {
	content: "◻"!important;
}
ul li {
	line-height: 1.5!important;
	break-inside: avoid-column;
}
@media (max-width: 768px) {
	.intro-p {
		font-size: 20px!important;
	}
}
.card.callout {
	margin-top: 64px!important;
	p {
	    width: 100%;
	}
}
.section-blogs {
	/*margin-top: 2em;*/
}
.row.logos div {
	padding: 0.5em;
	max-height: 70px;
	margin-top: 1em;
}
.row.logos img {
	max-height: 70px;
	width: auto;
	align-self: center;
}
@media (max-width: 1200px) and (min-width: 992px) {
	.home .logos {
    	margin: 0 11em;
    }
}
.uronav {
    max-height: 31.2em;
    margin: 0 auto;
    display: block;
}
.text-col-2 li {
	display: flex;
}
.dr-contact {
    margin-top: 1.5em;
    border-radius: 6px;
    & .title {
        border-radius: 6px 6px 0 0;
    }
}
@media (min-width: 500px) and (max-width: 992px) {
	.dr-contact {
    	display: block;
    	margin: 0 auto;
        max-width: 75%;
        margin-bottom: 1em;
        margin-top: 1.5em!important;
    }
}
@media (max-width: 1200px) {
	.uronav {
    	margin-top: 0;
    }
    .page-heading .container p {
    	line-height: 1.2;
    }
}
@media (min-width: 992px) {
	.text-col-2 {
		-webkit-column-count: 2;
		column-count: 2;
		-webkit-column-gap: 40px;
		column-gap: 40px;
	}
}
.inline-image {
    margin: 2em auto 3em;
    box-shadow: 0px 20px 50px -15px rgba(0,0,0,.4);
    display: block;
}
.post-sidebar{
    position: relative;
    z-index: 1010;
    .link-wrap{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99999;
    }
}
body.home {
    .page-heading {
        background-color: rgba(0,92,181,0);
        padding: 10.5% 0;
        @media (min-width: 1200px) {
            padding: 8.5% 0 10.5%;
        }
        .container {
            max-width: 1280px!important;
            @media (min-width: 1600px) {
                max-width: 1450px!important;
            }
            @media (min-width: 1700px) {
                max-width: 1600px!important;
                h1 {
                    font-size: 64px;
                }
            }
            h1 {
                line-height: 0.9!important;
                margin-bottom: 0.15em!important;
            }
        }
    }
}
blockquote.card.callout {
    @media (max-width: 600px) {
        padding-top: 48px!important;
        padding-bottom: 24px!important;
    }
}
blockquote.card.callout img {
    max-width: 120px;
    margin: 0 auto;
    height: auto;
    display: block;
    margin-bottom: 1em;
    @media (min-width: 600px) {
        max-width: 120px;
        margin-right: 16px;
        float: left;
        height: auto;
        margin-bottom: 15em;
    }
    @media (min-width: 720px) {
        margin-bottom: 9em;
    }
}
.about-content {
    margin-top: 6em;
    img {
    }
    p {
        margin-top: 0;
        padding: 0 0.5em;
    }
}
.about-image {
    &.card {
        border-radius: 6px;
        overflow: hidden;
    }
    p {
        padding: 0.25em 1em 0em 1em!important;
    }
}
.section-subfooter {
    background-size: cover;
    margin-bottom: 0;
    background-position: left center;
    padding: 2em 0;
    background-color: rgba(239, 169, 4, 1);
    position: relative;
    overflow: hidden;
    margin-top: 2em;
    .container {
        position: relative;
        z-index: 1;
    }
    h2 {
        font-family: 'Helvetica Neue',Helvetica,sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.2;
        letter-spacing: -0.5px;
        text-transform: initial;
        color: $uhc-blue;
    }
    strong {
        letter-spacing: 0.25px;
        color: $uhc-blue;
    }
    ul {
        margin-bottom: 0;
        & > li {
            border-bottom: none;
            font-size: 20px;
            margin-bottom: 0;
            &:before {
                color: $uhc-blue;
            }
        }
    }
}
.gold-square {
    position: absolute;
    background-color: rgba(239, 169, 4, 0.89);
    height: 1409px;
    width: 1146px;
    transform: rotate(33.75deg);
    left: -37%;
    top: -120%;
    z-index: 1;
    opacity: 0.8;
    @media (min-width: 992px) {
        left: initial;
        right: 56%;
    }
}
.card-specialty:hover .card-header img {
    transform: scale(1.1);
    filter: drop-shadow(1px 6px 7px rgba(0,0,0,.15));
}
.card-specialty {
    overflow: hidden;
    border-radius: 10px;
    max-width: 420px;
    margin: 0 auto;
    margin-bottom: 0.15em;
    display: flex;
    flex-direction: column;
    &:hover{
        .card-header{
            background-position: center -7px;
            background-size: 100%;
            &::before{
                height: 1.5em;
                transition: $global-transition;
            }
            img{
                transform: scale(1.1);
                transition: 0.3s all ease-in-out;
            }
        }
    }
    .card-header {
        background-image: url('/wp-content/themes/uhc-uswds/img/specialty-card-header.png');
        height: 7em;
        position: relative;
        background-size: 100%;
        background-position: center top;
        transition: $global-transition;
        &::before {
            background-image: url(/wp-content/themes/uhc-uswds/img/blob.svg);
            background-repeat: no-repeat;
            content: "";
            width: 26em;
            height: 3em;
            position: absolute;
            bottom: -.075em;
            left: -8.3em;
            transition: 0.3s ease-in-out;
        }
    }
    img {
        position: absolute;
        bottom: -1.5em;
        left: 1.5em;
        width: 6em;
        filter: drop-shadow(-1px 3px 4px transparentize($uhc-blue, 0.8));
        transition: 0.5s all cubic-bezier(1,1.61,.53,.75);
        z-index: 9999;
        transform-origin: bottom;
    }
    .card-button {
        border-radius: 10px;
        text-decoration: none;
        padding: 1em 2em;
        display: block;
        background-color: $uhc-gold;
        color: $uhc-blue;
        margin: 1em;
        font-weight: 700;
        font-family: $helvetica;
        position: relative;
        margin-top: auto;
        @media (min-width: 1400px) {
            font-size: 0.9em;
        }
        &:hover {
            &:after {
                width: 120%;
                background-color: rgba(255,255,255,0);
            }
        }
    }
    .card-title {
        font-weight: 700;
        font-family: $helvetica-condensed;
        padding: 0.5em 1em;
        font-size: 1.4em;
        color: $uhc-blue;
        line-height: 1.1;
        margin-bottom: 0;
        margin-top: 1em;
        z-index: 9999;
        position: relative;
        text-transform: uppercase;
        // min-height: 92px;
        min-height: 55px;
        word-wrap: break-word;
        @media (min-width: 1450px) and (max-width: 1600px) {
            font-size: 1.3em;
            margin-top: 1em;
        }
    }
    .card-number {
        font-family: $helvetica;
        padding: 0.25em 1em;
        font-size: 1.5em;
        color: $uhc-blue;
        line-height: 1.2em;
        position: relative;
        display: block;
        text-decoration-skip-ink: none;
        transition: $global-transition;
        @media (min-width: 1450px) and (max-width: 1600px) {
            font-size: 1.4em;
        }
        &::before {
            content: "";
            background-image: url(/wp-content/themes/uhc-uswds/img/phone.svg);
            width: 1em;
            height: 1.5em;
            display: inline-block;
            background-repeat: no-repeat;
            float: left;
            margin-right: 0.5em;
        }
        &:hover {
            text-decoration: none;
            color: lighten($uhc-blue, 5%);
            &::before {
                animation: wiggle 2.5s ease-in-out infinite;
            }
        }
        &:last-of-type {
            margin-bottom: 0.5em;
        }
    }
}

@keyframes wiggle {
    0%, 80%, 100% {
        transform: rotate(0deg);
    }
    85% {
        transform: rotate(15deg);
    }
    95% {
        transform: rotate(-5deg);
    }
}

.gold-emphasis {
    color: $uhc-gold;
}
#info {
    border-radius: 6px;
}
.newsletter-button {
    display: block;
    margin: 1em 0;
    padding: 0.5em 0.75em;
    text-align: center;
    text-decoration: none;
    background-color: $uhc-gold;
    border-radius: 6px;
    font-family: $helvetica;
    font-weight: 700;
    color: white;
    font-size: 1.5em;
}
.container {
    @media (min-width: 1450px) {
        max-width: 1280px;
    }
}
#specialties {
    @media (min-width: 992px) and (max-width: 1200px) {
        padding: 0 12%;
    }
    & > .m6 {
        @media (min-width: 992px) and (max-width: 1450px) {
            width: 50%!important;
        }
    }
}
a {
    transition: 0.15s ease-in-out;
    &:hover {
        transition: 0.15s ease-in-out;
        opacity: 0.9;
    }
}

.blobmove:hover path {
    d: path("M2,5 C2,2 8,2 8,5");
}
body.page-template-page-landing {
    .content-area {
        ul {
            margin-bottom: 60px;
            padding-top: 30px;
            @include StyledList;
        }
    }
    .page-heading {
        @media (min-width: 600px) {
            background-blend-mode: multiply;
        }
        .usa-button {
            &:hover {
                color: white;
            }
        }
    }
}
body.single-doctors main {
    margin-top: 3em;
}
.usa-nav-submenu {
    background-color:#fff;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
}
nav.usa-nav .usa-nav-primary li ul li {
    transform: none;
}
.usa-nav-primary a:hover {
    background: rgba(0,0,0,0);
}
nav ul li ul li {
    border-right: none;
    border-top: 1px solid #859cb3!important;
    &:nth-of-type(1){
        border-top: none!important;
    }
}
.usa-nav-primary button {
    transform: skew(33deg, 0);
}
.usa-nav-primary button[aria-expanded=true] {
    background: none!important;
        transform: skew(33deg, 0);
    span {
        border-bottom: none;
        color: #002a57;
    }
}
.usa-nav-submenu {
    @media screen and (min-width: $nav-width) {
        left: -5em!important;
        padding-top: 0!important;
        padding-bottom: 0!important;
    }
}
.usa-nav-link:hover span {
    @media screen and (min-width: $nav-width) {
        border-bottom: none;
    }
}
@media screen and (min-width: 951px) {
    .usa-nav-primary button {
        padding: 1.5rem 1.5rem 1.5rem;
        &[aria-expanded=false] {
            background-position: right 1.5rem top 50%;
        }
        &[aria-expanded=true] {
            background: initial!important;
        }
    }
}
.page-heading {
    .usa-button {
        color: $uhc-blue;
        &:hover {
            opacity: 0.9;
            transition: $global-transition;
            background-color: $uhc-gold;
            color: $uhc-blue;
        }
    }
}
.p-padd{
    padding-bottom: 14px;
}
aside .sidebar-card-lp {
    max-width: 466px!important;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 6px;
    border: none!important;
    transform: none!important;
    position: initial;
    margin: 0 auto;
    & .textwidget .lead {
        font-family: 'Helvetica Neue Condensed',Helvetica,sans-serif;
        font-size: 1.8em;
        line-height: 1.2;
        color: #757575;
        margin-bottom: 0!important;
        & + p {
            font-size: 1.4em;
            margin: 0;
            line-height: 1.2em;
            & + a {
                text-decoration: none;
                color: $uhc-gold;
                font-weight: 700;
                font-size: 1.6em;
                margin-top: .5em;
                display: block;
            }
        }
    }
    // & > .widget_text:nth-of-type(1)>div {
    //     padding: 6px 32px;
    //     position: relative;
    // }
    h5 {
        padding: 16px 32px 24px;
    }
}
.padd-right {
    padding-right: 3.5%;
    padding-bottom: 50px;
    @media (max-width: 1200px) {
        padding-right: 0!important;
    }
}
body.home .container {
    @media (min-width: 1600px) {
        max-width: 1450px;
    }
}
// body.home .widget_text:nth-of-type(2) {
//     background-image: url(/wp-content/themes/uhc-uswds/img/housecall_background.jpg);
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: top;
//     background-color: rgba(255, 255, 255, 0.4);
//     background-blend-mode: lighten;
// }
// Disable SVG Animation for FF (weird absolute position rules in FF make it much more difficult to have happen)
@-moz-document url-prefix() {
    .card-specialty {
        &:hover {
            .card-header {
                &::before {
                    height: 3em!important;
                }
            }
        }
        .card-header {
            &::before {
                left: -3em!important;
            }
        }
    }
}
.page-template-page-landing, body.search {
    a.my-chart {
        display: block;
        background-color: $uhc-blue;
        color: #fff;
        text-decoration: none;
        font-weight: 700;
        text-align: center;
        font-size: 2.5em;
        padding: .5em 2em;
        transition: .2s all;
        border-radius: 6px;
        margin-bottom: 12%;
    }
    .page-heading input#search-field-small {
        border-color: $uhc-gold;
    }
}
.inline-image {
    margin: 2em auto 3em;
    box-shadow: 0 20px 50px -15px rgba(0,0,0,.4);
    display: block;
    width: 100%;
    height: auto;
}

/*!
* Start Bootstrap - Simple Sidebar (https://startbootstrap.com/)
* Copyright 2013-2016 Start Bootstrap
* Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
*/
body {
    overflow-x: hidden;
}
/* Toggle Styles */
#wrapper {
    padding-left: 0;
    transition: $global-transition;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 999999999;
    #sidebar-wrapper {
        z-index: 99999999;
        position: fixed;
        top:0;
        right: 350px;
        height: 100%;
        margin-right: -350px;
        overflow-y: auto;
        overflow-x: hidden;
        background: #edf7fd;
        transition: $global-transition;
        width: 350px;
        box-shadow: -2px 0px 18px transparentize($uhc-blue, 0.95);
    }
    &.toggled {
        padding-left: 350px;
        visibility: hidden;
        opacity: 0;
        #sidebar-wrapper {
            width: 350px;
        }
        #page-content-wrapper {
            position: absolute;
            margin-right: -350px;
        }
    }
    @media(min-width:768px) {
        padding-left: 350px;
        &.toggled {
            padding-left: 0;
            #sidebar-wrapper {
                width: 0;
            }
            #page-content-wrapper {
                position: relative;
                margin-right: 0;
            }
        }
        #sidebar-wrapper {
            width: 350px;
        }
        #page-content-wrapper {
            padding: 20px;
            position: relative;
        }
    }
}
.closed{
    position: fixed;
    top: 0;
    z-index: 99999999999;
    padding: 6px 19px;
    background-color: $uhc-blue;
    color: #fff!important;
    text-decoration:none;
    right: 0;
    padding: 8px 19px;
    border-radius: 0px 0px 0px 25px;
    box-shadow: -4px 4px 18px transparentize($uhc-blue, 0.8);
    transform: translateX(50px);
    transition: $global-transition;
}
.seen{
    transform: translateX(0px);
}
#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}
/* Sidebar Styles */
.sidebar-nav {
    position: absolute;
    top: 0;
    width: 350px;
    margin: 0;
    padding: 62px 0 0;
    list-style: none;
    li {
        margin-bottom:0;
        a {
            display: block;
            text-decoration: none;
            color: $uhc-blue;
            padding: 20px;
            border: 1px solid transparent;
            border-left: none!important;
            border-right: none!important;
            font-family: $helvetica-condensed;
            font-weight: bold;
            opacity: 0.75;
            font-size: 1.05em;
            line-height: 1.2;
            max-width: 95%;
            &:hover {
                text-decoration: none;
                background: rgba(255,255,255,0.8);
                border: 1px solid transparentize($uhc-blue, 0.5);
                opacity: 1;
            }
            &.external-link:after {
                content: "\f08e";
                font-family: 'FontAwesome';
                right: 0;
                position: absolute;
                padding-right: 2em;
                font-size: 0.85em;
                transform: translateY(2.5px);
            }
        }
    }
    & > .sidebar-brand {
        height: 65px;
        font-size: 18px;
        line-height: 60px;
        a {
            color: #999999;
            &:hover {
                color: #fff;
                background: none;
            }
        }
    }
}

body.page-template-page-landing .content-area ul {
    margin-bottom: 40px;
    padding-top: 0.5em;
}
@media (max-width: 1600px) {
    body.page-template-page-landing .content-area h2:first-of-type {
        margin-top: 1em;
    }
}
#general-surgery + a ~ .card-button:after, #gastroenterology + a ~ .card-button:after, #oncology + a ~ .card-button:after, #orthopaedics + a ~ .card-button:after, #rheumatology + a ~ .card-button:after, #urology + a ~ .card-button:after, #dermatology + a ~ .card-button:after, #pediatrics + a ~ .card-button:after {
    content: "\f08e";
    font-family: 'FontAwesome';
    right: 0;
    position: absolute;
    padding-right: 2em;
}
.card-specialty .card-button:hover:after {
	width: inherit!important;
}
@import "universal";
// HouseCall Form Styles
// div#gform_wrapper_1 {
//     margin: 0;
//     form#gform_1 {
//         padding: 2em;
//         padding-top: 0;
//         li.gfield {
//             margin-top: 0;
//             label.gfield_label, label.gfield_consent_label {
//                 margin-top: 1em;
//             }
//         }
//         input#gform_submit_button_1 {
//             @extend .usa-button, .gold;
//             margin-bottom: 0;
//             display: block;
//             width: 100%!important;
//             max-width: 100%!important;
//         }
//         .ginput_container input {
//             border-radius: 6px;
//             border: 1px solid #7f7f7f;
//             padding: 5px 12px;
//         }
//         span.gfield_required {
//             color: $uhc-gold;
//         }
//         li#field_1_4, li#field_1_7 {
//             @media (min-width: 768px) {
//                 width: 50%;
//                 display: inline-grid;
//                 span.address_zip {
//                     width: 100%;
//                 }
//             }
//         }
//         label.gfield_consent_label {
//             font-size: 14px;
//         }
//         .gform_footer {
//             padding-bottom: 0;
//         }
//         div.ginput_recaptcha {
//             margin-top: 1.5em;
//         }
//         div#gform_confirmation_wrapper_1 {
//             margin: 2em;
//             line-height: 1.5;
//         }
//     }
// }

.uhc-bar {
    cursor: default;
    .usa-nav-container {
        p {
            cursor: default;
        }
    }
}
.social-link {
    display: inline-flex!important;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    width: 1.35em;
    height: 1.35em;
    font-size: 1.05em!important;
    opacity: 0.85;
    transition: $global-transition;
    text-decoration: none!important;
    color: white!important;
    &:hover {
        opacity: 1;
    }
    &:last-of-type {
        margin-right: 0;
    }
}
.social-link--container {
    margin-bottom: 1em;
    cursor: default;
    .social-link {
        font-size: 1.35em!important;
        &:first-of-type {
            margin-left: 0;
        }
    }
}
.mobile-social-menu {
    display: flex!important;
    padding: 0 0 12px 25px;
    border-bottom: 1px solid #0033a0;
    justify-content: flex-end;
    .social-link {
        border: none!important;
        width: 2em;
        height: 2em;
        padding: 0!important;
        font-size: 1.25em!important;
    }
    @media (min-width: 1049px) {
        display: none!important;
    }
}


.webp {
    .card-specialty {
        .card-header {
            background-image: url('/wp-content/themes/uhc-uswds/img/new-specialty-card-header.webp');
        }
    }
}