// Site Fonts
@font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('fonts/helvetica_neue_condensed/HelveticaNeueCondensed.eot');
    src: url('fonts/helvetica_neue_condensed/HelveticaNeueCondensed.eot?#iefix') format('embedded-opentype'),
        url('fonts/helvetica_neue_condensed/HelveticaNeueCondensed.ttf') format('truetype'),
        url('fonts/helvetica_neue_condensed/HelveticaNeueCondensed.woff') format('woff');
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: 'Helvetica Neue Condensed';
    src: url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.eot');
    src: url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.ttf') format('truetype'),
        url('fonts/helvetica_neue_condensed_bold/HelveticaNeue-CondensedBold.woff') format('woff');
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('fonts/helvetica_neue/HelveticaNeue-Light.eot');
    src: url('fonts/helvetica_neue/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/helvetica_neue/HelveticaNeue-Light.ttf') format('truetype'),
        url('fonts/helvetica_neue/HelveticaNeue-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Helvetica Neue';
    src: url('fonts/helvetica_neue/HelveticaNeue-Medium.eot');
    src: url('fonts/helvetica_neue/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/helvetica_neue/HelveticaNeue-Medium.ttf') format('truetype'),
        url('fonts/helvetica_neue/HelveticaNeue-Medium.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    text-rendering: optimizeLegibility;
}
@font-face {
    font-family: 'Iowan Old Style Bold';
    src: url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.eot');
    src: url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.ttf') format('truetype'),
        url('fonts/iowanold_st_bt/IowanOldStyleBT-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Bill Smith';
    font-style: normal;
    font-weight: normal;
    src: url('fonts/bill-smith.woff') format('woff');
}

.bill-smith {
    font-family: "Bill Smith", cursive;
    font-weight: normal!important;
    -webkit-font-smoothing: antialiased;
}

.helvetica-neue-med {
    font-family: $helvetica;
    font-weight: bold;
}
.iowanold-st-bld {
    font-family: $iowan-old;
}